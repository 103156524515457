import React from "react"
import SECTIONS from "utils/sectionConstants"
import RichText from "components/elements/richText"
import SectionCards from "components/sections/sectionCards"
import SideBySide from "components/sections/sideBySide"
import Wrapper from "components/sections/wrapper"

const ConditionsTemplate = ({ sections }) => {
  return sections.map((section, i) => {
    switch (section.__typename) {
      case SECTIONS.ContentfulSectionRichText:
        return <RichText className="conditions-template" {...section} key={i} />

      case SECTIONS.SectionCards:
        return <SectionCards {...section} key={i} />

      case SECTIONS.SideBySide:
        return <SideBySide {...section} key={i} />

      case SECTIONS.WrapperSection:
        return <Wrapper {...section} key={i} />

      default:
        return null
    }
  })
}
export default ConditionsTemplate
