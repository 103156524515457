import React from "react"
import PropTypes from "prop-types"
import CallToAction, { CallToActionProps } from "components/blocks/callToAction"
import Card, { CardProps } from "./card"
import "./sectionCards.scss"

const SectionCards = ({ actions, cards, headline, subheadline }) => (
  <div className="section text-center" id="ConditionsResources">
    <div className="container steps-container">
      {headline && <h1 className="headline">{headline}</h1>}
      {subheadline && <p className="sub-headline">{subheadline}</p>}
      {cards?.length && (
        <div className="cards-container">
          {cards.map((card, index) => (
            <Card {...card} index={index} key={index} />
          ))}
        </div>
      )}
      {actions?.map((item, index) => (
        <CallToAction
          ctaWrapper="d-flex justify-content-center"
          data={item}
          key={index}
          type="button"
        />
      ))}
    </div>
  </div>
)

export const SectionCardsProps = {
  actions: PropTypes.arrayOf(PropTypes.shape(CallToActionProps)),
  cards: PropTypes.arrayOf(PropTypes.shape(CardProps)),
  headline: PropTypes.string,
  subheadline: PropTypes.string,
}

SectionCards.propTypes = SectionCardsProps

export default SectionCards
