import React from "react"
import PropTypes from "prop-types"
import Link from "components/elements/link"
import { CallToActionProps } from "components/blocks/callToAction"
import "./card.scss"

const Card = ({ action, description, icon, title }) =>
  description.description && (
    <div style={{ width: "100%", margin: "8px 16px" }}>
      <Link className="custome-card-link" to={action.action.url}>
        {icon && icon.file && (
          <div className="image-container">
            <img
              alt={icon.description || title || "Card image"}
              src={icon.file.url}
            />
          </div>
        )}
        <div className="card-content">
          <h5 className="card-title">{title}</h5>
          <p className="card-description">{description.description}</p>
        </div>
      </Link>
    </div>
  )

export const CardProps = {
  action: PropTypes.shape(CallToActionProps),
  description: PropTypes.shape({
    description: PropTypes.string,
  }),
}

Card.propTypes = {
  ...CardProps,
  index: PropTypes.number,
}

export default Card
